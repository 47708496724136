import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { RingBaseSelect } from '../components/RingBaseSelect'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="結婚指輪専門ブランドCAD-platinumのオーダーメイドとは | 結婚指輪CAD-platinum"
        description="10,000点を超えるカスタマイズからオーダーメイドも選べる結婚指輪、CAD-platinumのコンセプトをご紹介。デザイン、品質、価格の理想をお求め頂けるサービス内容は動画でもわかりやすくご覧頂けます。"
        page="about"
    />
)

const AboutPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'CAD-platinumについて' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <HeroImageWrapper>
                        <StaticImage src="../images/about/about1.jpg" alt="" />
                    </HeroImageWrapper>
                    <CatchWrapper>
                        <CatchTxt size="l">CAD-platinumは</CatchTxt>
                        <CatchTxt size="l">デザイン</CatchTxt>
                        <CatchTxt size="l">品質</CatchTxt>
                        <CatchTxt size="l">価格</CatchTxt>
                        <CatchTxt size="l">すべての理想にだとり着く</CatchTxt>
                        <CatchTxt size="l">近道をご提案いたします</CatchTxt>
                    </CatchWrapper>
                    <MovieWrapper>
                        <MovieTitleWrapper>
                            <Txt>1分でわかる</Txt>
                            <Txt>CAD-platinum</Txt>
                        </MovieTitleWrapper>
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/cal0Ii0mLW8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </MovieWrapper>
                    <RingBaseSelect marginTop="80px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const HeroImageWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '16px 0',
})
const CatchTxt = styled(Txt)({
    marginBottom: '16px',
})
const MovieWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
})
const MovieTitleWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid #aaaaaa',
    padding: '4px 16px',
    marginBottom: '12px',
})

export default AboutPage
